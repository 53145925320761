<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Determine the molar mass of each of the following molecules:</p>

      <p class="mb-2">a) <chemical-latex :content="chemicalCompound1" /></p>

      <calculation-input
        v-model="inputs.molarMass1"
        class="mb-5"
        prepend-text="$\text{Molar mass:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">b) <chemical-latex :content="chemicalCompound2" /></p>

      <calculation-input
        v-model="inputs.molarMass2"
        class="mb-5"
        prepend-text="$\text{Molar mass:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) <chemical-latex :content="chemicalCompound3" /></p>

      <calculation-input
        v-model="inputs.molarMass3"
        prepend-text="$\text{Molar mass:}$"
        append-text="$\text{g/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';

export default {
  name: 'Question381',
  components: {
    CalculationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molarMass1: null,
        molarMass2: null,
        molarMass3: null,
      },
    };
  },
  computed: {
    versionNumber1() {
      return this.taskState.getValueBySymbol('versionNumber1').content;
    },
    chemicalCompound1() {
      switch (this.versionNumber1.value) {
        case 1:
          return 'CH3OH';
        case 2:
          return 'CH3NH2';
        case 3:
          return 'CH3Cl';
        case 4:
          return 'CH2Cl2';
        case 5:
          return 'C2H5OH';
        case 6:
          return 'CH3COOH';
        default:
          return 'error';
      }
    },
    versionNumber2() {
      return this.taskState.getValueBySymbol('versionNumber2').content;
    },
    chemicalCompound2() {
      switch (this.versionNumber2.value) {
        case 1:
          return 'SF6';
        case 2:
          return 'PCl5';
        case 3:
          return 'PCl3';
        case 4:
          return 'SF4';
        case 5:
          return 'SiCl4';
        case 6:
          return 'ClF3';
        default:
          return 'error';
      }
    },
    versionNumber3() {
      return this.taskState.getValueBySymbol('versionNumber3').content;
    },
    chemicalCompound3() {
      switch (this.versionNumber3.value) {
        case 1:
          return 'K2SO4';
        case 2:
          return 'CaCl2';
        case 3:
          return 'Na3PO4';
        case 4:
          return 'Mg(NO3)2';
        case 5:
          return 'Li2SO3';
        case 6:
          return 'NH4OH';
        default:
          return 'error';
      }
    },
  },
};
</script>
